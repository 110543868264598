<template>
  <!--发现组件-->
  <div id="explore">
    <section >
      <div class="weui-cells" style="font-size:18px">
        <router-link to="/explore/moments" class="weui-cell weui-cell_access" tag="div"
          v-on:click.native="momentNewMsg=false">
          <div class="weui-cell__hd">
            <img src="/images/find_icon-circle.png">
          </div>
          <div class="weui-cell__bd" style="line-height: 28px;">
            朋友圈
          </div>
          <div class="weui-cell__ft">
            <div class="home__notice" v-show="momentNewMsg">
              <img src="/images/header/yehua.jpg" alt="" class="">
              <i class="new-msg-dot"></i>
            </div>
          </div>
        </router-link>
      </div>

      <div class="weui-cells" style="font-size:18px">
        <router-link to="/wehchat/saoyisao?isExpore=1">
          <div class="weui-cell weui-cell_access" id="scanCell">
          <div class="weui-cell__hd">
            <img src="/images/shipinghao.png">
          </div>
          <div class="weui-cell__bd">
            视频号
          </div>
          <div class="weui-cell__ft">
            <div class="home__notice" v-show="momentNewMsg">
            
            
            </div>
          </div>
        </div>
        </router-link>
        <div class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/zhibo.png">
          </div>
          <div class="weui-cell__bd">
            直播
          </div>
                   <div class="weui-cell__ft">
            <div class="home__notice" v-show="momentNewMsg">
            
            
            </div>
          </div>
        </div>
      </div>
     
      <div class="weui-cells" style="font-size:18px">
        <router-link to="/wehchat/saoyisao?isExpore=1">
          <div class="weui-cell weui-cell_access" id="scanCell">
          <div class="weui-cell__hd">
            <img src="/images/find_icon-qrcode.png">
          </div>
          <div class="weui-cell__bd">
            扫一扫
          </div>
                   <div class="weui-cell__ft">
            <div class="home__notice" v-show="momentNewMsg">
            
            
            </div>
          </div>
        </div>
        </router-link>
        <div class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/tingyiting.png">
          </div>
          <div class="weui-cell__bd">
            听一听
          </div>
                   <div class="weui-cell__ft">
            <div class="home__notice" v-show="momentNewMsg">
            
            
            </div>
          </div>
        </div>
      </div>
      <!--<div class="weui-cells">
        <div class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <i class="home__icon-nearby___3PPpY home__icon___2XgfG"></i>
          </div>
          <div class="weui-cell__bd">
            附近的人
          </div>
        </div>
        <div class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <i class="home__icon-box___3tn0U home__icon___2XgfG"></i>
          </div>
          <div class="weui-cell__bd">
            漂流瓶
          </div>
        </div>
      </div>-->

      <div class="weui-cells" style="font-size:18px">
        <a class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/gouwu.png">
          </div>
          <div class="weui-cell__bd">
            购物
          </div>
                   <div class="weui-cell__ft">
            <div class="home__notice" v-show="momentNewMsg">
            
            
            </div>
          </div>
        </a>
        <div class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/find_icon-moregame.png">
          </div>
          <div class="weui-cell__bd">
            游戏
          </div>
                   <div class="weui-cell__ft">
            <div class="home__notice" v-show="momentNewMsg">
            
            
            </div>
          </div>
        </div>
      </div>
     <div class="weui-cells" style="font-size:18px">

        <div class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/xiaochengxu.png">
          </div>
          <div class="weui-cell__bd">
            小程序
          </div>
                   <div class="weui-cell__ft">
            <div class="home__notice" v-show="momentNewMsg">
            
            
            </div>
          </div>
        </div>
      </div>
    </section>
  
  </div>
</template>
<script>

  export default {
    mixins: [window.mixin],
    data() {
      return {
        pageName: "发现",
        momentNewMsg: true,
      }
    },
    activated() {
      this.$store.commit("toggleTipsStatus", -1)
    },
  }
</script>
<style lang="less">
  @import "../../assets/less/explore.less";
</style>